import React, { useEffect, useState } from 'react';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import CenteredContainer from '@/common/CenteredContainer';
import Footer from '@/footer';
import Header from '@/header';
import LocalizedMenuPage from '@/menu/categories/localized/LocalizedMenuPage';
import { CategoryDisplayNameProvider } from '@/menu/categories/context/CategoryDisplayNameProvider';
import initializePage from '@/common/pageSetup';
import { resetGetProductsGqlError } from '@/graphql/errors/actions';
import { RootState } from '@/rootStateTypes';
import { isNational } from '@/graphql/gqlDataManipulation';
import { NATIONAL } from '@/localization/constants';
import Routes from '@/router/routes';
import telemetry from '@/telemetry';
import { ItemAnalytics } from '@/dataAnalytics/analyticsTypes';
import { onLoadDealLoversPage } from '@/dataAnalytics/lineup/lineupAnalytics';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { openLocalizationRail } from '@/localization/actions';
import LocalizedMenuSkeleton from '@/menu/deals/LocalizedMenu/LocalizedMenuSkeleton';
import { ErrorPage } from '@/ErrorPage';

interface LineupPageProps {
  pageID: string;
  storeNumber: string;
}

const hasProductsGqlError = (
  state: RootState
): boolean => state.presentational.gqlErrors.getProductsError;
const isLocalized = (
  state: RootState
): boolean => state.domain.localization.localizedStore !== NATIONAL;
const isLocalizedOnServer = (
  storeNumber: string
): boolean => !isNational(storeNumber);

const Lineup = ({ pageID, storeNumber }: LineupPageProps): JSX.Element => {
  const hasGqlError = useSelector(hasProductsGqlError);
  const isLocalizedInRedux = useSelector(isLocalized);
  const analytics = useAnalytics();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const router = useRouter();
  const userIsLocalized = !isLocalizedInRedux && !isLocalizedOnServer(storeNumber);

  useEffect(() => {
    dispatch(resetGetProductsGqlError());

    if (userIsLocalized) {
      router.replace(Routes.DEALS, undefined, { shallow: true });
      telemetry.addCustomEvent('lineup-page-not-localized-redirect');
      // routeTo doesn't like LINEUP, use handleAfterLocalizing instead
      dispatch(openLocalizationRail({
        handleAfterLocalizing: () => {
          router.replace(Routes.LINEUP, undefined, { shallow: true });
        }
      }));
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLocalizedInRedux, storeNumber]);

  const onPageLoad = (items: ItemAnalytics[]) => {
    analytics.push(() => onLoadDealLoversPage(items));
  };

  if (hasGqlError) {
    return <ErrorPage />;
  }

  if (loading || userIsLocalized) {
    return (
      <>
        <Header />
        <LocalizedMenuSkeleton />
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <CenteredContainer>
        <CategoryDisplayNameProvider categoryID={pageID}>
          <LocalizedMenuPage
            fallbackPath={Routes.DEALS}
            showModalOnFallback
            onPageLoad={onPageLoad}
          />
        </CategoryDisplayNameProvider>
      </CenteredContainer>
      <Footer />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => initializePage(ctx, 'lineup', true);

export default Lineup;
